<template>
  <div class="approve-project-booking">
    <div class="card">
      <fd-form @submit="submitForm">
        <modal-body class="p-3">
          <h3 class="mb-4">Approve Booking</h3>
          <p>Set a due date for buyer to submit the SPA.</p>
          <p class="mb-4">
            If the buyer do not submit the SPA in the due date set, the booking
            will be cancelled.
          </p>

          <div class="row">
            <fd-date-picker
              v-model="form.salesPurchaseAgreementDueDate"
              class="col-12"
              label="SPA Due Date"
              :validators="[
                validator.required,
                (val) =>
                  validator.dateMin(
                    val,
                    $moment().add(1, 'days').format('YYYY-MM-DD')
                  )
              ]"
              :min="$moment().add(1, 'days').format('YYYY-MM-DD')"
            ></fd-date-picker>
          </div>
        </modal-body>

        <modal-footer class="row justify-content-end p-2">
          <button type="button" class="btn mr-1" @click="$emit('cancel')">
            Cancel
          </button>
          <button class="btn main">Approve Booking</button>
        </modal-footer>
      </fd-form>
    </div>
  </div>
</template>

<script>
import {
  required,
  dateMin
} from "@/components/GlobalComponents/FormComponents/Validator/rules";
import ProjectBooking from "@/models/projectBooking";

export default {
  components: {},
  mixins: [],
  props: {
    bookingId: {
      type: [Number, String],
      required: true
    }
  },
  data: function () {
    return {
      form: {
        salesPurchaseAgreementDueDate: ""
      },
      validator: {
        required: required,
        dateMin: dateMin
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    submitForm() {
      this.$emit(
        "submit",
        this.bookingId,
        ProjectBooking.postApprovePayload(this.form)
      );
    }
  }
};
</script>

<style lang="scss">
.cancel-prject-booking {
  min-width: 500px;
}
</style>
